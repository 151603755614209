import PlayerModel from '../models/PlayerModel';
import LatestGameModel from '../models/LatestGameModel';
import GoalieModel from '../models/GoalieModel';
import LatestGoalieGameModel from '../models/LatestGoalieGameModel';
import GameModel from '../models/GameModel';
import GoalModel from '../models/GoalModel';
import GoalieGameScoreModel from '../models/GoalieGameScoreModel';
import DivisionModel from '../models/DivisionModel';
import TeamStandingModel from '../models/TeamStandingModel';

class Parser{    
    static parsePoints(data){
        if (data === undefined){
            return undefined;
        }   
        
        return data;
    }
    static parsePlayers(data){
        if (data === undefined){
            return undefined;
        }                
        
        let players = [];
        for (let playerId in data)
        {
            if (isNaN(playerId))
            {
                continue;
            }

            let person = data[playerId];           
            let pm = undefined;

            if (person.primaryPosition === "G")
            {
                pm = new GoalieModel();
                let lg = new LatestGoalieGameModel();    
                
                pm.saves = person.saves;
                pm.shotsAgainst = person.shotsAgainst;                        
                pm.goalsAgainst = person.goalsAgainst;      
                pm.savePct = person.savePct;                            
                pm.gaa = person.gaa;
                pm.wins = person.wins;
                pm.losses = person.losses;
                pm.ot = person.ot;

                if (person.latestGame !== undefined)
                {
                    lg.date = person.latestGame.date;
                    lg.saves = person.latestGame.saves;
                    lg.shotsAgainst = person.latestGame.shotsAgainst;
                    lg.savePct = person.latestGame.savePct;
                    lg.toi = person.latestGame.toi;                
                    pm.latestGame = lg;                
                }                
            }
            else
            {
                pm = new PlayerModel();
                let lg = new LatestGameModel();
    
                pm.goals = person.goals;
                pm.assists = person.assists;                        
                pm.points = person.points;      
                pm.pointsPerGame = person.games > 0 ? person.points / person.games : 0;            
                pm.shotPct = person.shotPct;
                pm.plusMinus = person.plusMinus;
                pm.pim = person.pim !== undefined ? person.pim : 0;
                pm.hits = person.hits !== undefined ? person.hits : 0;
                pm.blocked = person.blocked !== undefined ? person.blocked : 0;
                pm.ppToi = person.ppToi !== undefined ? person.ppToi : "00:00";
                pm.shToi = person.shToi !== undefined ? person.shToi : "00:00";
                pm.faceoffPct = person.faceoffPct !== undefined ? person.faceoffPct * 100 : undefined;

                if (person.latestGame !== undefined)
                {
                    lg.date = person.latestGame.date;
                    lg.goals = person.latestGame.goals;
                    lg.assists = person.latestGame.assists;
                    lg.points = person.latestGame.points;
                    lg.timeOnIce = person.latestGame.timeOnIce;
                    lg.shots = person.latestGame.shots;
                    lg.plusMinus = person.latestGame.plusMinus;
                    lg.hits = person.latestGame.hits;
                    lg.pim = person.latestGame.pim;
                    pm.latestGame = lg;
                }                
            
            }
            pm.playerId = person.playerId;
            pm.isActive = person.isActive;
            pm.lastName = person.lastName;
            pm.firstName = person.firstName;  
            pm.primaryPosition = person.primaryPosition;                      
            pm.age = person.age;
            pm.nationality = person.nationality;
            pm.team = person.team;
            pm.teamId = person.teamId;
            pm.games = person.games;                                    
            pm.timeOnIcePerGame = person.timeOnIcePerGame;
            pm.injury = person.injury;
            pm.espnId = person.espnId;
    
            players.push(pm);           
        }   
                
        players.sort((a, b) => b.points - a.points);
        return players;
    }

    static parseScores(data){
        if (data === undefined){
            return undefined;
        }                
        
        let scores = [];
        for (let scoreId in data)
        {
            if (isNaN(scoreId))
            {
                continue;
            }
            
            let score = data[scoreId];           
            let gm = new GameModel();
            gm.gameId = score.gameId;
            gm.isUpcoming = score.isUpcoming;
            gm.date = score.date;
            gm.startTime = score.startTime;
            gm.homeTeam = score.homeTeam;
            gm.homeTeamId = score.homeTeamId;
            gm.awayTeam = score.awayTeam;
            gm.awayTeamId = score.awayTeamId;
            gm.homeTeamGoals = score.homeTeamGoals;
            gm.awayTeamGoals = score.awayTeamGoals;
            gm.currentPeriod = score.currentPeriod;
            gm.cpTimeRemaining = score.cpTimeRemaining;
            gm.extUrl = score.extUrl;            
            gm.recapUrl = score.recapUrl;            
            gm.status = score.status;
            gm.type = score.type;
            gm.seriesStatus = score.seriesStatus;
            gm.gameNumber = score.gameNumber;
            
            let goalies = [];
            score.goalies.forEach(g => 
                {
                    let goalieScore = new GoalieGameScoreModel();
                    goalieScore.playerId = g.playerId;
                    goalieScore.firstName = g.firstName;
                    goalieScore.lastName = g.lastName;
                    goalieScore.team = g.team;
                    goalieScore.saves = g.saves;
                    goalieScore.shotsAgainst = g.shotsAgainst;
                    goalieScore.savePct = g.savePct;
                    goalieScore.timeOnIce = g.timeOnIce;
                    goalies.push(goalieScore);
                });
            gm.goalies = goalies;

            let goals = [];
            for (let goalId in score.goals)
            {
                let goalData = score.goals[goalId];  

                let goal = new GoalModel();
                goal.time = goalData.time;
                goal.period = goalData.period;
                goal.homeGoals = goalData.homeGoals;
                goal.awayGoals = goalData.awayGoals;
                goal.scorerName = goalData.scorerName;
                goal.scorerId = goalData.scorerId;
                goal.assistOneName = goalData.assistOneName;
                goal.assistOneId = goalData.assistOneId;
                goal.assistTwoName = goalData.assistTwoName;
                goal.assistTwoId = goalData.assistTwoId;
                goal.strength = goalData.strength;
                goal.isEmptyNet = goalData.isEmptyNet;
                goal.isGameWinningGoal = goalData.isGameWinningGoal;
                goal.type = goalData.type;
                goal.ordinalNum = goalData.ordinalNum;
                goal.videoUrl = goalData.videoUrl;                
                
                goals.push(goal);
            }
            gm.goals = goals;

            scores.push(gm);           
        }    
         
        return scores;
    }

    static parseStandings(data){
        if (data === undefined){
            return undefined;
        }                
        
        let standings = [];
        for (let divisionId in data)
        {
            if (isNaN(divisionId))
            {
                continue;
            }
            
            let division = data[divisionId];           
            let dm = new DivisionModel();
            dm.name = division.name;
            dm.abbrev = division.divAbbrev;

            let teams = [];
            division.teams.forEach(t => 
            {
                let tsm = new TeamStandingModel();
                tsm.name = t.name;
                tsm.abbrev = t.abbrev;
                tsm.id = t.id;
                tsm.games = t.games;
                tsm.points = t.points;
                tsm.wins = t.wins;
                tsm.losses = t.losses;
                tsm.ot = t.ot;
                tsm.rw = t.rw;
                tsm.row = t.row;
                tsm.clinch = t.clinch;
                tsm.divPos = t.divPos;
                tsm.confPos = t.confPos;
                tsm.wcPos = t.wcPos;
                tsm.divAbbrev = division.divAbbrev;
                tsm.confAbbrev = division.confAbbrev;

                teams.push(tsm);
            });
            dm.teams = teams;
            standings.push(dm);           
        }    
         
        return standings;
    }

    static parsePlayerNews(data){
        if (data === undefined){
            return undefined;
        }                
        
        let latestNews = undefined;
        const feeds = data.feed.filter(f => f.type !== "Story");
        if (feeds.length > 0) {
            latestNews = {};            
            let newsItem = feeds[0];
            latestNews = { 
                headline: newsItem.headline, 
                story: newsItem.story,
                lastModified: new Date(newsItem.lastModified)
            };
        }
         
        return latestNews;
    }

    static parseUpdateId(data)
    {
        return data.updateId;
    }

    static parseVersionInfo(data)
    {
        return { updateId: data.updateId, appVersion: data.appVersion, lastUpdated: data.lastUpdated };
    }
}

export default Parser;