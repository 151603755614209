class LatestGameModel {    
    get date(){
        return this._date;
    }

    set date(newValue){
        this._date = newValue;
    }    

    get points(){
        return this._points;
    }

    set points(newValue){
        this._points = newValue;
    }

    get goals(){
        return this._goals;
    }

    set goals(newValue){
        this._goals = newValue;
    }

    get assists(){
        return this._assists;
    }

    set assists(newValue){
        this._assists = newValue;
    }   

    get timeOnIce(){
        return this._timeOnIce;
    }

    set timeOnIce(newValue){
        this._timeOnIce = newValue;
    }

    get shots(){
        return this._shots;
    }

    set shots(newValue){
        this._shots = newValue;
    }

    get plusMinus(){
        return this._plusMinus;
    }

    set plusMinus(newValue){
        this._plusMinus = newValue;
    }    

    get pim(){
        return this._pim;
    }

    set pim(newValue){
        this._pim = newValue;
    }  

    get hits(){
        return this._hits;
    }

    set hits(newValue){
        this._hits = newValue;
    }  
}

export default LatestGameModel;