import Parser from '../classes/Parser';
import DataModel from '../models/DataModel'

class DataFetcher {   
    static devServerAddress = "http://10.6.7.25:8082";
    static productionServerAddress = "https://data.nhlcountries.com";

    static async fetchData(nationality) {
        let pointData;
        let playerData;        
        let scoreData;        
        let standingsData;

        const serverAddress = process.env.NODE_ENV === 'production' ? this.productionServerAddress : this.devServerAddress;        

        const playersApiCall = await fetch(serverAddress + "/api/players/" + nationality);
        playerData = await playersApiCall.json();              

        const pointsApiCall = await fetch(serverAddress + "/api/points/" + nationality);
        pointData = await pointsApiCall.json();  
        
        const scoresApiCall = await fetch(serverAddress + "/api/scores");
        scoreData = await scoresApiCall.json();   

        const standingsApiCall = await fetch(serverAddress + "/api/standings");
        standingsData = await standingsApiCall.json();   
    
        const points = Parser.parsePoints(pointData);
        const players = Parser.parsePlayers(playerData);
        const scores = Parser.parseScores(scoreData);        
        const standings = Parser.parseStandings(standingsData);        

        const dataModel = new DataModel(points, players, scores, standings);
        return dataModel;
    }    

    static async fetchAllPlayerData() {        
        let allPlayerData;        

        if (process.env.NODE_ENV === 'production') {
                    const allPlayersApiCall = await fetch(this.productionServerAddress + "/api/allPlayers");
            allPlayerData = await allPlayersApiCall.json();              
    
        }        
        else {   
        
                const allPlayersApiCall = await fetch(DataFetcher.devServerAddress + "/api/allPlayers");
                allPlayerData = await allPlayersApiCall.json();                      
        }    
        
        const allPlayers = Parser.parsePlayers(allPlayerData);
        
        return allPlayers;
    }    

    static async fetchPlayerNews(playerEspnId)
    {        
        const apiCall = await fetch("https://site.api.espn.com/apis/fantasy/v2/games/fhl/news/players?days=100&limit=2&playerId=" + playerEspnId);
        const newsData = await apiCall.json();                  
           
        const playerNews = Parser.parsePlayerNews(newsData);
        return playerNews;
    }

    static async getUpdateId() {   
        let updateIdData;     
        
        if (process.env.NODE_ENV === 'production') {
            const updateIdApiCall = await fetch(this.productionServerAddress + "/api/updateId");
            updateIdData = await updateIdApiCall.json();                            
        }
        // else if (process.env.NODE_ENV === 'production') {
        //     const updateIdApiCall = await fetch("https://nhlcountries.com:8082/updateId");
        //     updateIdData = await updateIdApiCall.json();                            
        // }
        else {   
            const updateIdApiCall = await fetch(DataFetcher.devServerAddress + "/api/updateId");
            updateIdData = await updateIdApiCall.json();                            
        }

        const updateId = Parser.parseUpdateId(updateIdData);       
        return updateId;
    }    
    static async getVersionInfo() {   
        let versionInfoData;     
        if (process.env.NODE_ENV === 'production') {
            const versionInfoApiCall = await fetch(this.productionServerAddress + "/api/versionInfo");
            versionInfoData = await versionInfoApiCall.json(); 
        }
        // else if (process.env.NODE_ENV === 'production') {
        //     const versionInfoApiCall = await fetch("https://nhlcountries.com:8082/versionInfo");
        //     versionInfoData = await versionInfoApiCall.json();                            
        // }
        else {   
            const versionInfoApiCall = await fetch(DataFetcher.devServerAddress + "/api/versionInfo");
            versionInfoData = await versionInfoApiCall.json();                            
        }

        const versionInfo = Parser.parseVersionInfo(versionInfoData);       
        return versionInfo;
    }    
}

export default DataFetcher;