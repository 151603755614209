class GoalModel {
    get time(){
        return this._time;
    }

    set time(newValue){
        this._time = newValue;
    }

    get scorerName(){
        return this._scorerName;
    }

    set scorerName(newValue){
        this._scorerName = newValue;
    }

    get assistOneName(){
        return this._assistOneName;
    }

    set assistOneName(newValue){
        this._assistOneName = newValue;
    }
    
    get assistTwoName(){
        return this._assistTwoName;
    }

    set assistTwoName(newValue){
        this._assistTwoName = newValue;
    }

    get scorerId(){
        return this._scorerId;
    }

    set scorerId(newValue){
        this._scorerId = newValue;
    }

    get assistOneId(){
        return this._assistOneId;
    }

    set assistOneId(newValue){
        this._assistOneId = newValue;
    }
    
    get assistTwoId(){
        return this._assistTwoId;
    }

    set assistTwoId(newValue){
        this._assistTwoId = newValue;
    }

    set homeGoals(newValue){
        this._homeGoals = newValue;
    }

    get homeGoals(){
        return this._homeGoals;
    }

    set awayGoals(newValue){
        this._awayGoals = newValue;
    }

    get awayGoals(){
        return this._awayGoals;
    }

    set period(newValue){
        this._period = newValue;
    }

    get period(){
        return this._period;
    }

    set isEmptyNet(newValue){
        this._isEmptyNet = newValue;
    }

    get isEmptyNet(){
        return this._isEmptyNet;
    }

    set isGameWinningGoal(newValue){
        this._isGameWinningGoal = newValue;
    }

    get isGameWinningGoal(){
        return this._isGameWinningGoal;
    }

    set type(newValue){
        this._type = newValue;
    }

    get type(){
        return this._type;
    }

    set strength(newValue){
        this._strength = newValue;
    }

    get strength(){
        return this._strength;
    }

    get coordinateX() {
        return this._coordinateX;
    }

    set coordinateX(newValue){
        this._coordinateX = newValue;
    }

    get coordinateY() {
        return this._coordinateY;
    }
    
    set coordinateY(newValue){
        this._coordinateY = newValue;
    }

    set ordinalNum(newValue){
        this._ordinalNum = newValue;
    }

    get ordinalNum(){
        return this._ordinalNum;
    }

    get videoUrl() {
        return this._videoUrl;
    }

    set videoUrl(newValue){
        this._videoUrl = newValue;
    }
}

export default GoalModel;