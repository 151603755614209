class GoalieGameScoreModel {    
    get playerId(){
        return this._playerId;
    }

    set playerId(newValue){
        this._playerId = newValue;
    }

    get firstName(){
        return this._firstName;
    }

    set firstName(newValue){
        this._firstName = newValue;
    }

    get lastName(){
        return this._lastName;
    }

    set lastName(newValue){
        this._lastName = newValue;
    }

    get displayName() {
        return this._firstName + ' '  + this._lastName;
    }
    
    get team(){
        return this._team;
    }

    set team(newValue){
        this._team = newValue;
    }

    get saves(){
        return this._saves;
    }

    set saves(newValue){
        this._saves = newValue;
    }

    get shotsAgainst(){
        return this._shotsAgainst;
    }

    set shotsAgainst(newValue){
        this._shotsAgainst = newValue;
    }

    get savePct(){
        return this._savePct;
    }

    set savePct(newValue){
        this._savePct = newValue;
    }   

    get timeOnIce(){
        return this._timeOnIce;
    }

    set timeOnIce(newValue){
        this._timeOnIce = newValue;
    }    
}
export default GoalieGameScoreModel;