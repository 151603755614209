import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      app_name: "NHLCountries",
      main_countrySelectionLabel: "Country",
      main_languageSelectionLabel: "Language",
      main_gamesLabel: "Games",
      main_upcomingLabel: "Upcoming",
      main_lastNightLabel: "Summary",
      main_statsLabel: "Stats",
      main_nationalTeamLabel: "National team",
      main_standingsLabel: "Standings",
      main_newsLabel: "Injuries",
      main_playoffBracketLabel: "Playoff bracket",
      country_canada: "Canada",
      country_czechia: "Czechia",
      country_denmark: "Denmark",
      country_finland: "Finland",
      country_france: "France",
      country_germany: "Germany",
      country_latvia: "Latvia",
      country_norway: "Norway",
      country_russia: "Rogue nation",
      country_slovakia: "Slovakia",
      country_sweden: "Sweden",
      country_switzerland: "Switzerland",
      country_usa: "USA",
      home_updatingData: "Updating data...",
      home_updatingDataNote: "Loading injury data may takes dozens of seconds",
      home_noPlayers: "No players",
      home_lastNightGames: "Last night",
      home_todaysGames: "Tonight",
      home_dataUpdated: "Updated:",
      home_statApiDown1: "News: Still working with changes related NHL stats API change. Everything is not working yet.",      
      latestGameDay_totalPoints: "Total points",
      latestGameDay_note: "Player stats get updated after game has ended",
      nationalTeam_reserve: "Taxi squad",
      nationalTeam_notEnoughGames: "More games needed",
      nationalTeam_showAll: "All",
      nationalTeam_showFiltered: "Under playoff line",
      statColumn_name: "Name",
      statColumn_team: "T",
      statColumn_games: "GP",
      statColumn_goals: "G",
      statColumn_assists: "A",
      statColumn_points: "P",
      statColumn_pointsPerGame: "P/GP",
      statColumn_toi: "TOI",
      statColumn_plusMinus: "+/-",
      statColumn_shotPct: "S%",
      statColumn_primaryPosition: "Pos",
      statColumn_age: "Age",
      statColumn_penaltyMinutes: "PIM",
      statColumn_hits: "H",
      statColumn_shots: "S",
      statColumn_blocked: "B",
      statColumn_ppToi: "PPT",
      statColumn_shToi: "SHT",
      statColumn_saves: "S",
      statColumn_shotsAgainst: "SA",
      statColumn_savePct: "S%",
      statColumn_faceoffPct: "FO%",
      statColumn_gaa: "GAA",
      statColumn_wins: "W",
      statColumn_losses: "L",
      statColumn_ot: "OTL",

      scFull_name: "name",
      scFull_team: "team",
      scFull_games: "games",
      scFull_goals: "goals",
      scFull_assists: "assists",
      scFull_points: "points",
      scFull_pointsPerGame: "points per game",
      scFull_toi: "time on ice",
      scFull_plusMinus: "+/-",
      scFull_shotPct: "shooting percent",
      scFull_faceoffPct: "faceoff percent",
      scFull_primaryPosition: "primary position",      
      scFull_pim: "penalty minutes",
      scFull_hits: "hits",
      scFull_shots: "shots",
      scFull_blocked: "blocks",
      scFull_ppToi: "power play time on ice",
      scFull_shToi: "shorthanded time on ice",
      scFull_saves: "saves",
      scFull_shotsAgainst: "shots against",
      scFull_savePct: "save percent",
      scFull_gaa: "goals against average",
      scFull_wins: "wins",
      scFull_losses: "losses",
      scFull_ot: "overtime losses",

      stats_seasonStats: "Season statistics",
      stats_averageJoe: '"Average Joe"',
      stats_summitSam: '"Summit Sam"',
      stats_skaters: "Skaters",
      stats_goalies: "Goalies",
      stats_sortLabel: "Order",
      stats_asc: "asc",
      stats_desc: "desc",
      stats_showSelected: "Selected country",
      stats_showAll: "All",
      pointsPerGameDay_title: "Points per day",
      pointsPerGameDay_distribution: "Point distribution",
      pointsPerGameDay_note: "Still under construction...",
      shotType_tipIn: "Tip-in",
      shotType_wristShot: "Wrist shot",
      shotType_slapShot: "Slap shot",
      shotType_deflected: "Deflected",
      shotType_snapShot: "Snap shot",
      shotType_backhand: "Backhand",
      shotType_wrapAround: "Wrap-around",
      shotType_poke: "Poke",
      shotType_bat: "Bat",
      goalType_pp: "PP",
      goalType_sh: "SH",
      goalType_en: "EN",
      gameEndingType_so: "SO",
      gameEndingType_ot: "OT",
      gameStatus_ppd: "Postponed",
      gameStatus_tbd: "TBD",
      day_monday: "Mon",
      day_tuesday: "Tue",
      day_wednesday: "Wed",
      day_thursday: "Thu",
      day_friday: "Fri",
      day_saturday: "Sat",
      day_sunday: "Sun",
      scores_showNoScores: "No scores",
      scores_showSelected: "Selected country",
      scores_showAll: "Show all",
      scores_noGames: "No games", 
      scores_legend_dtd: "Out (day to day)", 
      scores_legend_out: "Out for unspecified time", 
      scores_legend_ir: "On Injured Reserve",
      scores_game: "Game",
      scores_tied: "tied",
      scores_lead: "lead",
      scores_win: "win",
      scores_notStarted: "series tied 0-0",
      standings_games: "G",
      standings_wins: "W",
      standings_losses: "L",
      standings_ot: "OTL",
      standings_rw: "RW",
      standings_row: "ROW",
      standings_gr: "GR",
      standings_highlight: "Teams with players from selected country are highlighted",      
      standings_points: "P",
      standings_l_rw: "Regulation time wins",
      standings_l_row: "Regulation and overtime time wins",
      standings_l_gr: "Games remaining",
      plBracket_east: "East",
      plBracket_west: "West",
      plBracket_wc: "WC",
      plBracket_wc_l: "Wild card",
      plBracket_underLine: "Under the line",
      plBracket_description: "First round playoff pairs based on current standings"
    },
  },
  fi: {
    translation: {
      main_countrySelectionLabel: "Maa",
      main_languageSelectionLabel: "Kieli",
      main_gamesLabel: "Ottelut",
      main_upcomingLabel: "Tulossa",
      main_lastNightLabel: "Yhteenveto",
      main_statsLabel: "Tilastot",
      main_nationalTeamLabel: "Maajoukkue",
      main_standingsLabel: "Sarjataulukot",
      main_newsLabel: "Sairastupa",
      main_playoffBracketLabel: "Pudotuspeliparit",
      country_canada: "Kanada",
      country_czechia: "Tshekki",
      country_denmark: "Tanska",
      country_finland: "Suomi",
      country_france: "Ranska",
      country_germany: "Saksa",
      country_latvia: "Latvia",
      country_norway: "Norja",
      country_russia: "Roistovaltio",
      country_slovakia: "Slovakia",
      country_sweden: "Ruotsi",
      country_switzerland: "Sveitsi",
      country_usa: "Yhdysvallat",
      home_updatingData: "Päivitetään tietoja...",
      home_updatingDataNote: "Poissaolotietojen lataaminen voi kestää useita kymmeniä sekunteja",
      home_noPlayers: "Ei pelaajia",
      home_lastNightGames: "Viime yönä",
      home_todaysGames: "Ensi yönä",
      home_dataUpdated: "Päivitetty:",
      home_statApiDown1: "Huom. Työ jatkuu vielä uuden NHL:n tilastorajapinnan käyttöönotossa. Kaikki ei toimi vielä kuten aiemmin.",      
      latestGameDay_totalPoints: "Pisteet yhteensä",
      latestGameDay_note: "Pelaajatilastot päivittyvät viivellä pelin päättymisen jälkeen",
      nationalTeam_reserve: "Varalla",
      nationalTeam_notEnoughGames: "Lisää näyttöjä tarvitaan",
      nationalTeam_showAll: "Kaikki",
      nationalTeam_showFiltered: "Pudotuspeliviivan alla",
      statColumn_name: "Nimi",
      statColumn_team: "J",
      statColumn_games: "O",
      statColumn_goals: "M",
      statColumn_assists: "S",
      statColumn_points: "P",
      statColumn_pointsPerGame: "P/P",
      statColumn_toi: "A",
      statColumn_plusMinus: "+/-",
      statColumn_shotPct: "L%",
      statColumn_faceoffPct: "A%",
      statColumn_primaryPosition: "Pp",
      statColumn_age: "Ikä",
      statColumn_penaltyMinutes: "RM",
      statColumn_hits: "T",
      statColumn_shots: "L",
      statColumn_blocked: "B",
      statColumn_ppToi: "YVA",
      statColumn_shToi: "AVA",
      statColumn_saves: "T",
      statColumn_shotsAgainst: "L",
      statColumn_savePct: "T%",
      statColumn_gaa: "PMK",
      statColumn_wins: "V",
      statColumn_losses: "H",
      statColumn_ot: "JAH",

      scFull_name: "nimi",
      scFull_team: "joukkue",
      scFull_games: "ottelut",
      scFull_goals: "maalit",
      scFull_assists: "syötöt",
      scFull_points: "pisteet",
      scFull_pointsPerGame: "pisteet per peli",
      scFull_toi: "peliaika",
      scFull_plusMinus: "+/-",
      scFull_shotPct: "laukaisuprosentti",
      scFull_faceoffPct: "aloitusprosentti",
      scFull_primaryPosition: "pelipaikka",      
      scFull_pim: "jäähyminuutit",
      scFull_hits: "taklaukset",
      scFull_shots: "laukaukset",
      scFull_blocked: "blokit",
      scFull_ppToi: "ylivoima-aika",
      scFull_shToi: "alivoima-aika",
      scFull_saves: "torjunnat",
      scFull_shotsAgainst: "laukauksia",
      scFull_savePct: "torjuntaprosentti",
      scFull_gaa: "päästettyjen maalien keskiarvo",
      scFull_wins: "voitot",
      scFull_losses: "häviöt",
      scFull_ot: "jatkoaikahäviöt",

      stats_seasonStats: "Kausitilastot",
      stats_averageJoe: '"Keskinen"',
      stats_summitSam: '"Summanen"',
      stats_skaters: "Kenttäpelaajat",
      stats_goalies: "Maalivahdit",
      stats_sortLabel: "Järjestys",
      stats_asc: "nouseva",
      stats_desc: "laskeva",
      stats_showSelected: "Valittu maa",
      stats_showAll: "Kaikki",
      pointsPerGameDay_title: "Tehdyt pisteet per päivä",
      pointsPerGameDay_distribution: "Pistejakauma",
      pointsPerGameDay_note: "Työn alla vielä...",
      shotType_tipIn: "Ohjaus",
      shotType_wristShot: "Ranneveto",
      shotType_slapShot: "Lämäri",
      shotType_deflected: "Kimmoke",
      shotType_snapShot: "Nopea veto",
      shotType_backhand: "Rystykuti",
      shotType_wrapAround: "Vanhanaikainen",
      shotType_poke: "Tökkäys",
      shotType_bat: "Huitaisu",
      goalType_pp: "YV",
      goalType_sh: "AV",
      goalType_en: "TM",
      gameEndingType_so: "VL",
      gameEndingType_ot: "JA",
      gameStatus_ppd: "Siirretty",
      gameStatus_tbd: "Ei tietoa",
      day_monday: "Ma",
      day_tuesday: "Ti",
      day_wednesday: "Ke",
      day_thursday: "To",
      day_friday: "Pe",
      day_saturday: "La",
      day_sunday: "Su",
      scores_showNoScores: "Ei tuloksia",
      scores_showSelected: "Valittu maa",
      scores_showAll: "Näytä kaikki",
      scores_noGames: "Ei pelejä", 
      scores_legend_dtd: "Poissa päivä kerrallaan", 
      scores_legend_out: "Poissa tuntemattoman ajan", 
      scores_legend_ir: "Sairaslistalla (IR)", 
      scores_game: "Ottelu",
      scores_tied: "sarja tasan",
      scores_lead: "johtaa sarjaa",
      scores_win: "voitti sarjan",
      scores_notStarted: "sarja tasan 0-0",
      standings_games: "O",
      standings_wins: "V",
      standings_losses: "H",
      standings_ot: "JAH",
      standings_rw: "VV",
      standings_row: "VVJ",
      standings_gr: "OJ",
      standings_highlight: "Valitun maan pelaajia sisältävien joukkueiden nimet korostettu",      
      standings_points: "P",
      standings_l_rw: "Voitot varsinaisella peliajalla",
      standings_l_row: "Voitot varsinaisella tai jatkoajalla",
      standings_l_gr: "Otteluita jäljellä",
      plBracket_east: "Itä",
      plBracket_west: "Länsi",
      plBracket_wc: "VK",
      plBracket_wc_l: "Villi kortti",
      plBracket_underLine: "Viivan alla",
      plBracket_description: "Ensimmäisen kierroksen pudotuspeliparit tämänhetkisen sarjatilanteen mukaan"
    },
  },
};
i18next
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources,
    debug: true,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;