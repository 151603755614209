import { useTranslation } from "react-i18next";
import GaugeComponent from 'react-gauge-component'

const TwentyPointsGauge = (props) => {  
    const { t } = useTranslation();
    
    return (                
        <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          // gradient: true,
          subArcs: [
            {
              limit: 5,
              color: '#81D4FA',
              showTick: true,                            
            },
            {
              limit: 10,
              color: '#FFEE58',
              showTick: true,              
            },
            {
              limit: 15,
              color: '#9CCC65',
              showTick: true,              
            },
            {
              limit: 20, color: '#558B2F', showTick: true,              
            },
            {
              color: '#FB8C00',              
            }
          ]
        }}
        pointer={{
          color: '#345243',
          length: 0.80,
          width: 15,
          elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: value => value + ' p' },
          tickLabels: {
            type: 'outer',
            valueConfig: { formatTextValue: value => value + ' pistettä', fontSize: 10 },
            ticks: [
              { value: 5 },
              { value: 10 },
              { value: 15 },
              { value: 20 },
            ],
          }
        }}
        value={props.points}
        minValue={0}
        maxValue={25}
        style={{ background: "black", maxWidth: "400px"}}
      />        
    );
}

export default TwentyPointsGauge;