class DivisionModel {
    constructor(name, teams){        
        this._name = name;
        this._teams = teams;
    }    
    get name(){
        return this._name;
    }

    set name(newValue){
        this._name = newValue;
    }

    get abbrev(){
        return this._abbrev;
    }

    set abbrev(newValue){
        this._abbrev = newValue;
    }

    get teams(){
        return this._teams;
    }

    set teams(newValue){
        this._teams = newValue;
    }
}

export default DivisionModel;