class GameModel {
    static days  = {
        1: "ma",
        2: "ti",
        3: "ke",
        4: "to",
        5: "pe",
        6: "la",
        7: "su"
    }

    get isUpcoming(){
        return this._isUpcoming;
    }

    set isUpcoming(newValue){
        this._isUpcoming = newValue;
    }

    get startTime(){
        return this._startTime;
    }

    set startTime(newValue){
        this._startTime = newValue;
    }

    get homeTeam(){
        return this._homeTeam;
    }

    set homeTeam(newValue){
        this._homeTeam = newValue;
    }

    get awayTeam(){
        return this._awayTeam;
    }

    set awayTeam(newValue){
        this._awayTeam = newValue;
    }

    get homeTeamId(){
        return this._homeTeamId;
    }

    set homeTeamId(newValue){
        this._homeTeamId = newValue;
    }

    get awayTeamId(){
        return this._awayTeamId;
    }

    set awayTeamId(newValue){
        this._awayTeamId = newValue;
    }

    get homeTeamGoals(){
        return this._homeTeamGoals;
    }

    set homeTeamGoals(newValue){
        this._homeTeamGoals = newValue;
    }

    get awayTeamGoals(){
        return this._awayTeamGoals;
    }

    set awayTeamGoals(newValue){
        this._awayTeamGoals = newValue;
    }

    get currentPeriod(){
        return this._currentPeriod;
    }

    set currentPeriod(newValue){
        this._currentPeriod = newValue;
    }

    get cpTimeRemaining(){
        return this._cpTimeRemaining;
    }

    set cpTimeRemaining(newValue){
        this._cpTimeRemaining = newValue;
    }
    
    get status(){
        return this._status;
    }    

    set status(newValue){
        this._status = newValue;
    }

    get recapUrl() {
        return this._recapUrl;
    }

    set recapUrl(newValue){
        this._recapUrl = newValue;
    }

    get extUrl() {
        return this._extUrl;
    }

    set extUrl(newValue){
        this._extUrl = newValue;
    }

    get goals(){
        return this._goals;
    }

    set goals(newValue){
        this._goals = newValue;
    }

    get goalies(){
        return this._goalies;
    }

    set goalies(newValue){
        this._goalies = newValue;
    }

    get type(){
        return this._type;
    }

    set type(newValue){
        this._type = newValue;
    }    

    get seriesStatus(){
        return this._seriesStatus;
    }

    set seriesStatus(newValue){
        this._seriesStatus = newValue;
    }    

    get gameNumber(){
        return this._gameNumber;
    }

    set gameNumber(newValue){
        this._gameNumber = newValue;
    }   

    get gameId(){
        return this._gameId;
    }

    set gameId(newValue){
        this._gameId = newValue;
    }   
     
    get date(){
        return this._date;
    }

    set date(newValue){
        this._date = newValue;
    }   
}

export default GameModel;