import './StatsToday.css';
import React from 'react';
import { useTranslation } from "react-i18next";
import Utilities from '../classes/Utilities';
import TwentyPointsGauge from '../components/TwentyPointsGauge';

const StatsToday = (props) => {
    const { t } = useTranslation();    
        
    let currentDayPoints = Utilities.getCountryPointsFromScores(props.data.scores, props.data.players);           
    const currentDate = Utilities.getCurrentRoundDate();
    const currentDateString = `${currentDate.getUTCFullYear()}-${Utilities.pad((currentDate.getUTCMonth() + 1), 2)}-${Utilities.pad(currentDate.getUTCDate(), 2)}`;
    props.data.points[currentDateString] = currentDayPoints;    

    let points = [];    
    let keys = Object.keys(props.data.points).sort();        
    
    for (let dateId in keys)
    {        
        points.push(props.data.points[keys[dateId]]);
    }
    let allTeams = [...props.data.standings[0].teams, ...props.data.standings[1].teams, ...props.data.standings[2].teams, ...props.data.standings[3].teams];    
    let teamsPlayingToday = [];
    props.data.scores.filter(s => s.date === currentDateString).forEach((s) => {
        teamsPlayingToday.push(s.homeTeamId);
        teamsPlayingToday.push(s.awayTeamId);
    });

    const getGameTime = (game) => {        
        let rem = new Date().setHours(0, 0, 0);
        if (game.cpTimeRemaining !== "END")
        {
            const [h, m] = game.cpTimeRemaining.split(":");
            rem = new Date(new Date().setHours(0,h,m));            
        }
        
        let totalMinutes = (game.currentPeriod <= 3 || game.type === 3) ? game.currentPeriod * 20 : 65;        
        const totalTime = new Date(new Date().setHours(0, totalMinutes,0));
        const gameTime = new Date(totalTime - rem);
        
        return Utilities.pad((gameTime.getUTCHours() * 60 + gameTime.getUTCMinutes()), 2) + ":" + Utilities.pad(gameTime.getUTCSeconds(), 2);
    }
    //var teamIds = [...new Set(teams.map(team => team.id))];

    let latestDate = keys.at(-1);    
    let skaters = props.data.players.filter(p => p.primaryPosition !== "G" && p.isActive).filter(p => (p.latestGame !== undefined && p.latestGame.date === latestDate) || teamsPlayingToday.includes(p.teamId)) 
    
    let players = [];
    let currentTime = new Date();
    skaters.forEach((skater) => {
        let scoringString = "0+0";
        let points = 0;
        let goals = 0;
        let assists = 0;
        if (skater.latestGame !== undefined && skater.latestGame.date === latestDate) {
            scoringString = `${skater.latestGame.goals}+${skater.latestGame.assists}`;
            points = skater.latestGame.goals + skater.latestGame.assists;
            goals = skater.latestGame.goals;
            assists = skater.latestGame.assists;
        }

        const game = props.data.scores.find(s => s.homeTeamId === skater.teamId || s.awayTeamId === skater.teamId);        
        let status = "";
        let statusCode = 0;
        var startTime = new Date(game.startTime);
        
        let startTimeString = String(String(startTime.getHours()).padStart(2, '0') + ':' + String(startTime.getMinutes()).padStart(2, '0'));
        if (game.status === "5" || game.status === "6" || game.status === "7") {            
            if (skater.latestGame === undefined) {                
                statusCode = -1;
            }
            else {
                status = "";
                statusCode = 2;
            }            
        }
        else if (game.status === "1") {                       
            status = `Alkaa ${startTimeString}`;
            statusCode = 0;
        }
        else if (game.status === "2" && startTime < currentTime) {
            status = `Alkaa pian (${startTimeString})`;
            statusCode = 0;
        }
        else if (game.status === "2") {
            status = `Alkaa ${startTimeString}`;
            statusCode = 0;
        }
        else if (game.status === "3" || game.status === "4") {
            const gameTime = getGameTime(game);
            if (skater.latestGame === undefined && getGameTime(game) !== "00:00") {                
                statusCode = -1;
            }
            else {
                status = `Käynnissä (${gameTime})`;
                statusCode = 1;
            }
            
        }      
        
        if (skater.injury > 0) {
            statusCode = -1;
        }        

        let gameString = undefined;
        if (statusCode < 1) {
            gameString = `${allTeams.find(t => t.id === game.homeTeamId).abbrev}-${allTeams.find(t => t.id === game.awayTeamId).abbrev}`;
        }
        else {
            gameString = `${allTeams.find(t => t.id === game.homeTeamId).abbrev} ${game.homeTeamGoals} - ${allTeams.find(t => t.id === game.awayTeamId).abbrev} ${game.awayTeamGoals}`;
        }
        players.push({
            name: skater.lastName + " " + skater.firstName.at(0),
            startTime: game.startTime,
            team: skater.team,
            points: points,
            goals: goals,
            assists: assists,
            score: scoringString,
            game: gameString,
            status: statusCode,
            statusText: status
        });
        
    });
    
    let currentPlayers = sort("pointsLatestDesc", players.filter(p => p.status >= 1));
    let upcomingPlayers = sort("startTimeAsc", players.filter(p => p.status === 0));
    let notPlayingPlayers = sort("nameAsc", players.filter(p => p.status === -1));
    const weekday = Utilities.getWeekday(t, latestDate);    
    let lastUpdatedString = "";

    if (props.lastUpdated !== undefined)
    {
        const lastUpdated = new Date(props.lastUpdated);
        lastUpdatedString = (String(lastUpdated.getHours()) + ':' + String(lastUpdated.getMinutes()).padStart(2, '0') + ":" + String(lastUpdated.getSeconds()).padStart(2, '0'));
    }    
    
    let counter = 0;
    function getStatusStyle(status) {
        if (status === -1) {
            return "NotPlaying";
        }
        else if (status === 1) {
            return "GameOngoing";
        }
    }    

    function sort(order, players)
    {   
        if (order === "pointsLatestDesc")
        {                    
            return players.sort((a, b) => b.points - a.points || b.goals - a.goals || a.name.localeCompare(b.name));
        }       
        if (order === "startTimeAsc")
        {                    
            return players.sort((a, b) => (a.startTime > b.startTime) - (a.startTime < b.startTime) || a.game.localeCompare(b.game) || a.name.localeCompare(b.name));
        }   
        if (order === "nameAsc")
        {                    
            return players.sort((a, b) => a.name.localeCompare(b.name));
        }   
    }    

    return (
        <div className='MainStatsToday'>
            <div style={{ paddingTop: "12px"}}>                                
                <h2 className="Heading">20p</h2>                  
            </div>             
            <TwentyPointsGauge
                points={points.at(-1)}/>            
            { (points.at(-1) >= 20) && <div style={{ fontStyle: 'italic'}}>"Pasila, Porilaisten marssi!"</div>}
            <div className="Content">
            <h4>{weekday + " " + new Date(latestDate).toLocaleDateString()}</h4>  
            {/* <label >{lastUpdatedString}</label> */}
            {
                skaters.length > 0 &&
                <div>         
                    {
                        currentPlayers.length > 0 &&
                        <div>
                            {/* <h4>Ottelu käynnissä tai loppunut</h4> */}
                            <table className="StatsTodayTable">
                                <tbody>
                                    {currentPlayers.map((item) => (
                                    <tr className={getStatusStyle(item.status)} key={counter++} >                                
                                        <td style={{ width: "90px"}}>{item.name}</td>                    
                                        <td style={{ width: "30px"}}>{item.team}</td>
                                        <td style={{ width: "30px"}}>{item.score}</td>
                                        <td style={{ width: "75px", fontSize: "x-small"}}>{item.game}</td>                                                
                                        <td>{item.statusText}</td>                    
                                    </tr>
                                    ))}
                                </tbody>                                                
                            </table>    
                        </div>
                    }                               
                    {
                        notPlayingPlayers.length > 0 &&
                        <div>
                            <h5>Ei kokoonpanossa</h5>
                            <table className="StatsTodayTable">
                                <tbody>
                                    {notPlayingPlayers.map((item) => (
                                    <tr className={getStatusStyle(item.status)} key={counter++} >                                
                                        <td style={{ width: "90px"}}>{item.name}</td>                    
                                        <td style={{ width: "30px"}}>{item.team}</td>                                                                                
                                        <td/>
                                    </tr>
                                    ))}
                                </tbody>                                                
                            </table>    
                        </div>
                    } 
                    {
                        upcomingPlayers.find(p => p.status !== -1) !== undefined &&
                        <div>
                            <h5>Tulossa</h5>                    
                            <table className="StatsTodayTable">  
                                <tbody>
                                    {upcomingPlayers.map((item) => (
                                    <tr className={getStatusStyle(item.status)} key={counter++}>                                
                                        <td style={{ width: "100px"}}>{item.name}</td>                    
                                        <td style={{ width: "35px"}}>{item.team}</td>                                
                                        <td style={{ width: "75px"}}>{item.game}</td>                                                
                                        <td>{item.statusText}</td>                    
                                    </tr>
                                    ))}
                                </tbody>                                              
                            </table>    
                        </div>
                    }                    
                </div>
            }                        
            </div>
            <div style={{ paddingTop: "20px"}}>                                                
                <div style={{ fontSize:'x-small'}}>Tämän seurannan ja muuta tarjoaa <a href='https://nhlcountries.com'>NHLCountries</a></div>                                     
            </div> 
        </div>        
    );
}

export default StatsToday;